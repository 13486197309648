@import url(~fluid-player/src/css/fluidplayer.css);
body {
  background-color: black !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.App-logo {
  height: 280px; }

.App-header {
  background-color: #66ccff;
  padding: 20px;
  color: white; }

.app-header-live {
  max-height: 100em; }
  .app-header-live .App-logo {
    margin: 10px;
    height: 100px; }

.App .title {
  font-weight: normal; }

.App-header a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: .2s all; }

.App-header a:hover {
  opacity: .7; }

.countdown-screen {
  text-align: center;
  color: white; }

.Countdown {
  margin: 10px auto;
  padding-bottom: 20px;
  line-height: 2.3em; }

.Countdown-col {
  display: inline-block; }

.Countdown-col-element {
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column; }

.Countdown-col-element strong {
  font-size: 50px; }

.chat-timestamp {
  display: block; }

.player > div {
  height: 800px !important; }

.page-live {
  background-color: black !important; }
  .page-live a {
    color: white !important; }
  .page-live span {
    color: white !important; }
  .page-live .ui.comments .comment .metadata {
    color: white !important; }
  .page-live .ui.comments .comment .text {
    color: white !important; }
  .page-live .chat-area {
    border: solid;
    border-color: #450447;
    border-width: 0.5em;
    height: 100%; }
    .page-live .chat-area .chat-timestamp {
      color: gray !important; }
    .page-live .chat-area .chat-actions {
      float: right;
      margin-right: 5px; }
      .page-live .chat-area .chat-actions .label {
        cursor: pointer; }
    .page-live .chat-area .ui.header {
      color: #66ccff;
      text-align: center;
      padding: 5px; }
    .page-live .chat-area .chat-widget {
      height: 420px;
      overflow-y: scroll; }
      .page-live .chat-area .chat-widget::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em; }
      .page-live .chat-area .chat-widget::-webkit-scrollbar-thumb {
        background: slategray; }
      .page-live .chat-area .chat-widget::-webkit-scrollbar-track {
        background: #b8c0c8; }
      .page-live .chat-area .chat-widget {
        scrollbar-face-color: slategray;
        scrollbar-track-color: #b8c0c8; }
    .page-live .chat-area .chat-widget-tall {
      height: 520px; }
    .page-live .chat-area .chat-message-deleted {
      text-decoration: line-through; }
  .page-live .results-area {
    border: solid;
    border-color: #450447;
    border-width: 0.5em;
    min-height: 300px;
    padding: 1em; }
    .page-live .results-area h2 {
      color: #66ccff; }

.finalVideo {
  margin: auto !important; }

.uploadField {
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .uploadField .sub-header {
    text-align: center;
    font-size: 18px;
    margin-bottom: 7px; }
  .uploadField .draggable-container-hovered {
    border: 2px dashed white !important; }
  .uploadField .draggable-container {
    min-width: 90%;
    max-width: 95%;
    min-height: 6rem;
    max-height: 8rem;
    background-color: rgba(147, 148, 148, 0.23);
    padding: 6px;
    border-radius: 4px;
    border: 2px dashed rgba(95, 92, 92, 0.2);
    font-size: 14px;
    display: flex;
    position: relative;
    overflow: hidden; }
    .uploadField .draggable-container .hovered {
      border: 2px dashed white !important; }
    .uploadField .draggable-container #file-browser-input {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      color: transparent;
      opacity: 0;
      z-index: 10; }
    .uploadField .draggable-container .files-preview-container {
      display: flex;
      width: 100%;
      height: fit-content;
      flex-direction: row;
      flex-wrap: nowrap;
      z-index: 99;
      overflow-x: auto;
      overflow-y: hidden;
      flex-shrink: 0;
      padding: 5px; }
      .uploadField .draggable-container .files-preview-container .file {
        width: 5em;
        height: 58px;
        background-color: rgba(101, 97, 97, 0.34);
        position: relative;
        margin-left: 8px;
        border: 1px solid #fff;
        border-radius: 4px;
        box-shadow: 0 0 14px 3px rgba(15, 15, 15, 0.2);
        flex-shrink: 0; }
        .uploadField .draggable-container .files-preview-container .file img {
          width: 100%;
          height: 100%; }
        .uploadField .draggable-container .files-preview-container .file .container {
          display: flex;
          position: absolute;
          flex-direction: column;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          transition: background, 160ms ease-in-out; }
          .uploadField .draggable-container .files-preview-container .file .container:hover {
            background-color: rgba(154, 151, 151, 0.29); }
            .uploadField .draggable-container .files-preview-container .file .container:hover .remove-btn {
              visibility: visible; }
          .uploadField .draggable-container .files-preview-container .file .container .remove-btn {
            color: #ca4240;
            width: fit-content;
            position: absolute;
            right: -7px;
            top: -7px;
            z-index: 99;
            cursor: pointer;
            visibility: hidden; }
          .uploadField .draggable-container .files-preview-container .file .container .progress-bar {
            position: absolute;
            width: 53px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-self: center; }
    .uploadField .draggable-container .helper-text {
      position: absolute;
      display: flex;
      justify-self: center;
      align-self: center;
      left: 50%;
      transform: translateX(-50%);
      color: #797676;
      text-align: center; }
    .uploadField .draggable-container .file-browser-container {
      position: absolute;
      display: flex;
      justify-self: center;
      align-self: center;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%); }

.progress .label {
  color: white !important; }

@media only screen and (max-width: 1024px) {
  /*Tablet*/
  .App-header {
    /* background-color: red!important; DEV */ }
  .ui.column.grid > [class*="twelve wide"].column, .ui.grid > .column.row > [class*="twelve wide"].column, .ui.grid > .row > [class*="twelve wide"].column, .ui.grid > [class*="twelve wide"].column,
  .ui.column.grid > [class*="four wide"].column, .ui.grid > .column.row > [class*="four wide"].column, .ui.grid > .row > [class*="four wide"].column, .ui.grid > [class*="four wide"].column {
    width: 49% !important; }
  .player > div {
    height: 400px !important; }
  .page-live .chat-area .ui.basic.segment {
    max-height: 400px; }
  .page-live .chat-area .chat-widget {
    height: 200px; }
  .app-header-live .App-logo {
    height: 80px;
    margin: 0 10px; } }

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .App-header {
    /* background-color: gold!important; DEV */ }
  .ui.column.grid > [class*="twelve wide"].column, .ui.grid > .column.row > [class*="twelve wide"].column, .ui.grid > .row > [class*="twelve wide"].column, .ui.grid > [class*="twelve wide"].column,
  .ui.column.grid > [class*="four wide"].column, .ui.grid > .column.row > [class*="four wide"].column, .ui.grid > .row > [class*="four wide"].column, .ui.grid > [class*="four wide"].column {
    width: 100% !important; }
  .player > div {
    margin-bottom: 10px; } }

@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /*Mobile*/
  .App-header {
    /*background-color: aquamarine!important; DEV */ }
  .ui.column.grid > [class*="twelve wide"].column, .ui.grid > .column.row > [class*="twelve wide"].column, .ui.grid > .row > [class*="twelve wide"].column, .ui.grid > [class*="twelve wide"].column,
  .ui.column.grid > [class*="four wide"].column, .ui.grid > .column.row > [class*="four wide"].column, .ui.grid > .row > [class*="four wide"].column, .ui.grid > [class*="four wide"].column {
    width: 100% !important; }
  .player > div {
    height: 200px !important; }
  .app-header-live .App-logo {
    height: 60px;
    margin: 0 10px; } }
